<template>
  <div class="app-div">
    <div class="main">
      <div class="banner flex-row-center flex-row-center-y">
        <div class="title-face flex-column-center-cross">
          <!-- @todo miss -->
          <div class="title">SBS</div>
        </div>
      </div>
      <div class="middle flex-row-column-center">
        <div class="business-scope">
          <div class="busy-title tac">&nbsp;</div>
          <div class="content-wrapper">
            <div v-for="(item, index) in busyContent" :key="index" class="busy-content flex-row-y-start">
              <div class="number">{{ item.number }}</div>
              <div class="text">{{ item.text }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="footer flex-column-row-center">
      <div class="footer-c">
        <div v-for="(item, index) in footerMsg" :key="index" class="f-msg-wrapper flex-row-center-y">
          <div class="icon">
            <img :src="item.iconAddr" alt="" />
          </div>
          <div class="msg">{{ item.msg }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      busyContent: [
        {
          number: '01',
          text: 'Proses pembuatan program atau layanan komputer dengan menggunakan bahasa seperti Java, SQL, Python, Go, Rust, atau gabungan program atau layanan lainnya untuk menjalankan tugas-tugas yang diperlukan bagi sebuah enterprise atau pekerjaan. Perangkat lunak ini dapat digunakan untuk hal-hal yang sangat sederhana seperti website fungsional, aplikasi seluler untuk pembelian, pengorganisasian, atau penjadwalan, atau untuk hal yang lebih kompleks seperti perangkat lunak untuk manajemen rantai pasok, pengiriman aplikasi, atau proyek-proyek konstruksi besar. Perangkat lunak ini berperan penting dalam membantu bisnis mencapai otomatisasi proses dan meningkatkan efisiensi bisnis serta kepuasan pelanggan. Pembuatan aplikasi ini adalah sinonim dengan pembuatan perangkat lunak dan dilakukan melalui beberapa tahapan yang sama yaitu mengumpulkan kebutuhan, membuat prototipe, melakukan pengujian, implementasi, dan integrasi.'
        },
        {
          number: '02',
          text: 'Aplikasi telah memainkan peran penting dalam membantu perusahaan mencapai otomatisasi proses, meningkatkan efisiensi bisnis, dan meningkatkan kepuasan pelanggan. Pengembangan aplikasi sinonim dengan pengembangan perangkat lunak dan mengikuti langkah-langkah yang sama: pengumpulan kebutuhan, desain prototipe, pengujian, implementasi, dan integrasi.'
        },
        {
          number: '03',
          text: 'Ini memungkinkan pembeli dan penjual melakukan transaksi kapan saja dan di mana saja tanpa batasan geografis, sehingga lebih nyaman dan efisien, sambil juga mengurangi biaya dan risiko transaksi. Transaksi berbasis internet telah menjadi komponen penting dari perdagangan modern dan merupakan salah satu tren dari perkembangan bisnis masa depan.'
        },
        {
          number: '04',
          text: 'Kegiatan bisnis yang dilakukan dengan menggunakan internet dan teknologi elektronik lainnya, serta mengelola hubungan pelanggan elektronik di dalam integrasi ekonomi global saat ini, telah membuat e-commerce menjadi mode bisnis yang tidak dapat diabaikan dan akan terus menjadi semakin populer dan berkembang.'
        }
      ],
      footerMsg: [
        {
          iconAddr: require('./assets/images/tel.png'),
          msg: 'Nomor telepon: + 2130228030'
        },
        {
          iconAddr: require('./assets/images/email.png'),
          msg: 'Email: support@shiningblackstone.com'
        },
        {
          iconAddr: require('./assets/images/addrs.png'),
          msg: 'Alamat perusahaan: The Boulevard Office Lantai 5 Ruang C2, Jl. KH. Fakhrudin Raya, Kampung Bali, Tanah Abang, Jakarta Pusat 10250'
        }
      ]
    }
  },
  methods: {
  }
}
</script>

<style lang="scss" scoped>
@function vm($px) {
  @return calc($px / 1920) * 100vw;
}

.app-div {
  width: 100%;
  min-height: 100%;
  background: url('./assets/images/bg.jpg') no-repeat;
  background-size: cover;

  .banner {
    width: 100%;
    height: vm(380);
    background: url('./assets/images/banner.png') no-repeat;
    background-size: contain;

    .title-face {
      color: #ffffff;
      .title {
        font-size: vm(68);
        color: #ffffff;
        letter-spacing: vm(1.5);
        text-align: center;
        font-weight: 700;
        border-bottom: vm(3) solid;
        border-image: linear-gradient(to right, #4687ff, #09e6fd) 1;
      }

      .facebook-wrapper {
        width: vm(228);
        height: vm(48);
        box-sizing: border-box;
        padding: vm(10) vm(28);
        margin-top: vm(48);
        background: #ffffff;
        box-shadow: 0px 23px 10px -11px rgba(0, 58, 90, 0.25);
        border-radius: 24px;

        .icon {
          width: vm(30);
          height: vm(30);
        }

        .text {
          font-family: PingFangSC-Semibold;
          font-size: vm(24);
          color: #0067eb;
          text-align: center;
          line-height: vm(27);
          font-weight: 600;
        }

        .right-arrow {
          width: vm(14);
          height: vm(14);
        }
      }
    }
    & img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .middle {
    .business-scope {
      position: relative;
      margin-bottom: vm(60);

      .busy-title {
        font-family: Poppins-SemiBold;
        font-size: vm(32);
        color: #002865;
        letter-spacing: 0;
        font-weight: 600;
        margin: vm(8) 0;
      }

      .content-wrapper {
        display: flex;
        width: 80vw;
        margin: 0 auto;
        overflow: hidden;
        justify-content: flex-start;
        align-items: flex-end;
        flex-wrap: wrap;
      }

      .busy-content {
        margin-bottom: vm(30);
        flex: 1;
        min-width: calc((100% - 10px) / 3);
        margin-left: 10px;
        background-color: #fff;
        border-radius: 16px;
        font-size: vm(16);
        color: #333333;
        padding: vm(30) vm(30) vm(30) vm(25);

        .number {
          font-size: vm(32);
          color: #0044f5;
          margin-right: vm(18);
          font-weight: 900;
        }
      }

      .lock-img {
        position: absolute;
        top: vm(80);
        right: vm(86);
        width: vm(117);
        height: vm(126);

        & img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
}

.footer {
  width: 100%;
  height: vm(240);
  font-size: vm(16);
  color: #ffffff;
  font-weight: 400;
  background: url('./assets/images/bottom-bg.png') no-repeat;
  background-size: cover;

  .footer-c {
    .f-msg-wrapper {
      margin-bottom: vm(24);
    }

    .icon {
      width: vm(16);
      height: vm(16);

      & img {
        width: 100%;
        height: 100%;
        display: block;
      }
    }

    .msg {
      margin-left: vm(12);
    }
  }
}

.extend {
  width: 100%;
  height: 100%;
  display: block;
}
</style>
